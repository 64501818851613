import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/apps/dashboard/src/app/SentryUserIdentification.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientFeatureFlagProvider"] */ "/vercel/path0/ui/apps/dashboard/src/components/FeatureFlags/ClientFeatureFlagProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/ui/apps/dashboard/src/components/PageViewTracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ClientClerkProvider"] */ "/vercel/path0/ui/node_modules/.pnpm/@clerk+nextjs@5.6.4_next@14.2.10_react-dom@18.2.0_react@18.2.0/node_modules/@clerk/nextjs/dist/esm/app-router/client/ClerkProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["AuthenticateWithRedirectCallback","ClerkLoaded","ClerkLoading","RedirectToCreateOrganization","RedirectToOrganizationProfile","RedirectToSignIn","RedirectToSignUp","RedirectToUserProfile"] */ "/vercel/path0/ui/node_modules/.pnpm/@clerk+nextjs@5.6.4_next@14.2.10_react-dom@18.2.0_react@18.2.0/node_modules/@clerk/nextjs/dist/esm/client-boundary/controlComponents.js");
;
import(/* webpackMode: "eager", webpackExports: ["useAuth","useClerk","useEmailLink","useOrganization","useOrganizationList","useSession","useSessionList","useSignIn","useSignUp","useUser"] */ "/vercel/path0/ui/node_modules/.pnpm/@clerk+nextjs@5.6.4_next@14.2.10_react-dom@18.2.0_react@18.2.0/node_modules/@clerk/nextjs/dist/esm/client-boundary/hooks.js");
;
import(/* webpackMode: "eager", webpackExports: ["CreateOrganization","GoogleOneTap","OrganizationList","OrganizationProfile","OrganizationSwitcher","SignIn","SignInButton","SignInWithMetamaskButton","SignOutButton","SignUp","SignUpButton","UserButton","UserProfile","__experimental_UserVerification"] */ "/vercel/path0/ui/node_modules/.pnpm/@clerk+nextjs@5.6.4_next@14.2.10_react-dom@18.2.0_react@18.2.0/node_modules/@clerk/nextjs/dist/esm/client-boundary/uiComponents.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/packages/components/src/AppRoot/globals.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/ui/packages/components/src/AppRoot/fonts.css");
;
import(/* webpackMode: "eager", webpackExports: ["Tooltip","TooltipTrigger","TooltipContent","TooltipProvider","TooltipArrow"] */ "/vercel/path0/ui/packages/components/src/Tooltip/Tooltip.tsx");
